import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import styles from './MultipleSelectImageButtons.module.css';

const MultipleSelectImageButtons = (props) => {
  const {
    buttonsData,
    onChange,
    defaultValue = [],
  } = props;

  const [selectedValues, setSelectedValues] = useState(null);

  useEffect(() => {
    if (defaultValue) {
      const selectedValues = {};

      defaultValue.forEach((value) => {
        selectedValues[value] = true;
      });

      setSelectedValues(selectedValues);
    }

  }, [defaultValue]);

  const onChangeHandler = (value) => {
    let newValues = { ...selectedValues };

    if (value) {
      if (newValues[value]) {
        delete newValues[value];
      } else {
        newValues[value] = true;
      }
    } else {
      newValues = {};
    }

    onChange(Object.keys(newValues));
    setSelectedValues(newValues);
  };

  const renderButton = (buttonData) => {
    let active = false;

    if (buttonData.value) {
      active = selectedValues?.[buttonData.value];
    } else {
      active = !!selectedValues && !Object.keys(selectedValues).length;
    }

    return (
      <div
        key={buttonData.value}
        className={cn(styles.button, { [styles.active]: active })}
        onClick={() => onChangeHandler(buttonData.value)}
      >
        <img src={buttonData.icon} />
        {buttonData.label}
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      {buttonsData.map(renderButton)}
    </div>
  );
};

export default MultipleSelectImageButtons;
