import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import ScrollToTop from '../../hoc/ScrollToTop';
import { updateViewportHeight } from '../../utils/functions';
import { getStepsData } from '../../actions/steps';
import Preloader from '../../components/Preloader/Preloader';
import Login from '../Login/Login';
import Registration from '../Registration/Registration';
import GetMealPlan from '../GetMealPlan/GetMealPlan';
import Dashboard from '../Dashboard/Dashboard';
import DashboardDev from '../DashboardDev/DashboardDev';
import Terms from '../Terms/Terms';
import Dev from '../Dev/Dev';
import Privacy from '../Privacy/Privacy';
import ConfirmEmail from '../ConfirmEmail/ConfirmEmail';
import PageNotFound from '../PageNotFound/PageNotFound';

import styles from './App.module.css';

function App (props) {
  const { isFetching, userData } = props;

  useEffect(() => {
    updateViewportHeight();
  }, []);

  const renderCommonRoutes = () => (
    <>
      <Route exact path="/terms" Component={Terms} />
      <Route exact path="/privacy" Component={Privacy} />
      <Route path="*" Component={PageNotFound}/>
    </>
  );

  if (userData) {
    return (
      <div className={styles.wrapper}>
        {isFetching && <Preloader/>}
        <Toaster />
        <ScrollToTop />
        <Routes>
          <Route exact path="/" Component={DashboardDev} />
          {renderCommonRoutes()}
        </Routes>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      {isFetching && <Preloader/>}
      <Toaster />
      <ScrollToTop />
      <Routes>
        <Route exact path="/" Component={Dashboard} />
        <Route exact path="/dev" Component={Dev} />
        <Route exact path="/get-meal-plan" Component={GetMealPlan} />
        <Route exact path="/login" Component={Login} />
        <Route exact path="/register" Component={Registration} />
        <Route exact path="/confirm-email" Component={ConfirmEmail} />
        {renderCommonRoutes()}
      </Routes>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isFetching: state.preloader.isFetching,
  userData: state.auth.userData,
});

const mapDispatchToProps = {
  getStepsData,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);