import React from 'react';
import { Field } from 'formik';
import cn from 'classnames';

import styles from './FormControls.module.css';

const errorMessage = (meta) => meta.touched && meta.error && (
  <div className={styles.validationMessage}>{meta.error}</div>
);

export const Input = (props) => {
  const { label, type, placeholder, className } = props;

  return (
    <Field {...props}>
      {({ field, meta }) => (
        <div>
          {label && <div className={styles.label}>{label}</div>}
          <input
            className={cn(styles.input, className)}
            type={type}
            placeholder={placeholder}
            {...field}
          />
          {errorMessage(meta)}
        </div>
      )}
    </Field>
  );
};

export const Select = (props) => {
  const { label, placeholder, className, options } = props;

  return (
    <Field {...props}>
      {({ field, meta }) => (
        <div>
          {label && <div className={styles.label}>{label}</div>}
          <select
            {...field}
            className={cn(styles.input, className)}
          >
            {placeholder && <option value="" disabled hidden>{placeholder}</option>}
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {errorMessage(meta)}
        </div>
      )}
    </Field>
  );
};

export const Checkbox = (props) => {
  const { className, children } = props;

  return (
    <Field {...props}>
      {({ field, meta }) => (
        <label className={className}>
          <input type="checkbox" {...field}  />
          <span>{children}</span>
          {errorMessage(meta)}
        </label>
      )}
    </Field>
  );
};