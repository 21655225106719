import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form, useFormikContext } from 'formik';
import cn from 'classnames';

import { register } from '../../actions/auth';
import FinishPage from '../../components/Steps/FinishPage';
import { GoogleButton } from '../../components/GoogleButton/GoogleButton';
import { Input, Select, Checkbox } from '../../components/FormsControls/FormControls';
import styles from './Registration.module.css';

const RegisterSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Name is to short').required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().min(6, 'Password too short').required('Required'),
  repeatPassword: Yup
    .string()
    .oneOf([Yup.ref('password')], 'Passwords don\'t match')
    .required('Required'),
  agree: Yup.bool()
    .oneOf([true], 'You must agree to the terms') // Проверка, что чекбокс отмечен
    .required('Required'),
});

const SubmitButton = () => {
  const { isValid, dirty } = useFormikContext();
  return (
    <button 
      className={cn('green-button', styles.login)}
      disabled={!isValid || !dirty}
    >
      Create account
    </button>
  );
};

const defaultValues = { 
  country: 'GB',
  name: '', 
  email: '', 
  password: '', 
  repeatPassword: '',
};

const countryOptions = [
  {
    value: 'GB',
    label: 'United Kingdom',
  },
  {
    value: 'US',
    label: 'United States',
  },
];

const Registration = (props) => {
  const { register } = props;

  const [showMessage, setShowMessage] = useState(false);

  const registerHandler = (data, formikData) => {
    register(data, formikData, () => {
      setShowMessage(true);
    });
  };

  if (showMessage) {
    return (
      <FinishPage 
        message="Registration was successful. A message was sent to your number to confirm your email."
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      <Link 
        className="back-button"
        to="/get-meal-plan" 
      />
      <div className={styles.content}>
        <div className="medium-text">
          Create account
        </div>
        <div className="small-text">
          Meal Well makes healthy eating simple and enjoyable every day.
        </div>
        <Formik
          onSubmit={registerHandler}
          initialValues={defaultValues}
          validationSchema={RegisterSchema}
        >
          <Form className={styles.form}>
            <Select
              className={styles.select}
              name="country"
              options={countryOptions}
            />
            <Input
              className={styles.input}
              name="name"
              type="text"
              placeholder="Enter your name"
            />
            <Input
              className={styles.input}
              name="email"
              type="text"
              placeholder="Password"
            />
            <div className={styles.password}>
              Create password
            </div>
            <Input
              className={styles.input}
              name="password"
              type="password"
              placeholder="Password"
            />
            <Input
              className={styles.input}
              name="repeatPassword"
              type="password"
              placeholder="Confirm Password"
            />
            <SubmitButton />
            <GoogleButton />
            <Checkbox name="agree" className={styles.checkbox}>
              I agree to {' '}
              <Link className={styles.link} to="/terms" target="_blank">Terms of Service</Link>{' '}
              and{' '}
              <Link className={styles.link} to="/privacy" target="_blank">Privacy Policy</Link>
            </Checkbox>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

const mapDispatchToProps = { register };

export default connect(null, mapDispatchToProps)(Registration);
