import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { GoogleButton } from '../../components/GoogleButton/GoogleButton';
import styles from './GetMealPlan.module.css';

const GetMealPlan = () => {

  return (
    <div className={styles.wrapper}>
      <Link 
        className={'back-button'} 
        to="/dev" 
      />
      <div className={styles.content}>
        <div className="medium-text">
          Let’s create a meal plan for you
        </div>
        <div className="small-text">
          Meal Well makes eating well easy and enjoyable every day.
        </div>
        <Link className={cn('green-button', styles.createButton)} to="/register">
          Create account
        </Link>
        <GoogleButton />
        <div className={cn('medium-text', styles.already)}>
          Already have account?
        </div>
        <Link className={cn('green-button', styles.login)} to="/login">
          Log in
        </Link>
      </div>
    </div>
  );
};

export default GetMealPlan;
