import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { 
  nextStep,
  addMobileQuestionerData,
  getMobileStepsData,
 } from '../../actions/steps';
import steps from '../../components/StepsDev/steps';

import styles from './DashboardDev.module.css';

const Dashboard = (props) => {
  const {
    getMobileStepsData,
    addMobileQuestionerData,
    nextStep,
    stepsData,
  } = props;

  useEffect(() => {
    getMobileStepsData();
  }, []);

  console.log('===stepsData', stepsData);

  const StepComponent = steps[stepsData.step];

  const nextStepHandler = (data = {}, callback) => {
    console.log('===data', data);

    if (_.isEmpty(data)) {
      nextStep();
    } else {
      addMobileQuestionerData(data, callback);
    }
  };

  // country GB need for first version of site (You can delete it in future)
  if (!stepsData?.step && stepsData.country === 'GB') {
    return;
  }

  return (
    <div className={styles.wrapper}>
      <StepComponent
        onSave={nextStepHandler}
        stepsData={stepsData}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  stepsData: state.steps.stepsData,
});

const mapDispatchToProps = {
  getMobileStepsData,
  nextStep,
  addMobileQuestionerData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);