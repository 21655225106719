import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import styles from './MainHeader.module.css';

const MainHeader = () => {
  return (
    <header className={styles.header}>
      <Link className={styles.logo} to="/dev" />
      <nav className={styles.menuWrapper}>
        <ul className={styles.menu}>
          <li className={styles.menuItem}>
            <NavLink
              to="/dev"
              className={styles.menuLink}
            >
              Blog
            </NavLink>
          </li>
          <li className={styles.menuItem}>
            <NavLink
              to="/dev"
              className={styles.menuLink}
            >
              Contacts
            </NavLink>
          </li>
          <li className={styles.menuItem}>
            <NavLink
              to="/get-meal-plan"
              className={styles.menuLink}
            >
              Get your meal plan
            </NavLink>
          </li>
          <li className={styles.menuItem}>
            <NavLink
              to="/dev"
              className={styles.menuLink}
            >
              Download App
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default MainHeader;
