import React from 'react';
import cn from 'classnames';

import styles from './Steps.module.css';

const Step1 = (props) => {
  const { onSave } = props;

  return (
    <div className={cn(styles.stepWrapper, styles.firstPage)}>
      <div className={styles.firstPageBackground} />
      <div className={styles.contentBlock}>
        <p className={styles.stepHeader}>
          Let’s begin!
        </p>
        <p className={styles.stepDescription}>
          Help us craft the perfect meal plan for you — just answer a few quick questions!
        </p>
        <button
          className="green-button"
          onClick={() => onSave()}
        >
          Start
        </button>
        <div className={styles.greenLink}>
          Remind me later
        </div>
      </div>
    </div>
  );
};

export default Step1;
