const steps = {
  allergies: [],
  goal: 'balanced',
  excluded_meals: [
      'fish'
  ]
};

console.log('===STEPS', steps);

export const questionarySteps = [
  {
    field: 'excluded_meals',
    step: 7,
  },
  {
    field: 'physical_activity',
    step: 6,
  },
  {
    field: 'height',
    step: 5,
  },
  {
    field: 'weight',
    step: 4,
  },
  {
    field: 'birth_date',
    step: 3,
  },
  {
    field: 'gender',
    step: 2,
  },
];