import React from 'react';

import styles from './Privacy.module.css';


const Privacy = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.logo}/>
      <h1>Privacy Policy</h1>
      <p>
        <strong>Introduction </strong>
        We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy
        Policy explains how we collect, use, and protect the data you provide to us when using our service to generate
        personalised meal plans.
      </p>

      <p>
        <strong>Information We Collect </strong>
        We collect the following personal information to create your personalised meal plan:
      </p>

      <ul>
        <li>
          Weight
        </li>
        <li>
          Age
        </li>
        <li>
          Gender
        </li>
        <li>
          Height
        </li>
        <li>
          Physical activity level
        </li>
        <li>
          Allergies
        </li>
      </ul>

      <p>
        <strong>How We Use Your Information </strong>
        The information you provide is used solely for the purpose of generating and sending you a personalised meal
        plan. Your data allows us to tailor the meal plan to your specific needs and preferences.
      </p>

      <p>
        <strong>Data Protection and Security </strong>
        We take the security of your personal information seriously. We implement appropriate technical and
        organisational measures to protect your data from unauthorised access, loss, or misuse.
      </p>

      <p>
        <strong>Data Sharing </strong>
        We do not share your personal information with any third parties, except where required by law or necessary to
        provide our service to you.
      </p>

      <p>
        <strong>Retention of Information </strong>
        We retain your personal information only for as long as necessary to fulfil the purposes for which it was
        collected, or as required by law.
      </p>

      <p>
        <strong>Your Rights </strong>
        You have the right to access, correct, or delete your personal information. You may also withdraw your consent
        to the use of your data at any time. To exercise these rights, please contact us at{' '}
        <a href="mailto: hello@mealwell.ai" target="_blank" rel="noreferrer">hello@mealwell.ai</a>.
      </p>

      <p>
        <strong>Changes to This Policy </strong>
        We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we will notify
        you of any significant changes.
      </p>

      <p>
        <strong>Contact Us </strong>
        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at{' '}
        <a href="mailto: hello@mealwell.ai" target="_blank" rel="noreferrer">hello@mealwell.ai</a>.
      </p>

      <p>
        <strong>Consent </strong>
        By providing your personal information and using our service, you consent to the collection and use of your data
        in accordance with this Privacy Policy.
      </p>

      <p>
        <strong>Last Updated </strong>
        This Privacy Policy was last updated on 15.07.2024.
      </p>
    </div>
  );
};

export default Privacy;
