import React, { useState } from 'react';
import cn from 'classnames';

import { excludeMealItems } from '../../constants/excludeMeals';
import MultipleSelectImageButtons from '../MultipleSelectImageButtons/MultipleSelectImageButtons';
import StrictMenu from './StrictMenu';
import ProgressBar from '../ProgressBar/ProgressBar';
import styles from './Steps.module.css';

const Step7 = (props) => {
  const { onSave, stepsData } = props;

  const [excludedMeals, setExcludedMeals] = useState(stepsData.excluded_meals || null);
  const [showStrictForm, setShowStrictForm] = useState(false);

  const onChangeHandler = (value) => {
    setExcludedMeals(value);
  };

  const saveMeals = () => onSave({ 
    excluded_meals: excludedMeals,
   });

  const onSaveHandler = () => {
    if (excludedMeals.length > 2) {
      setShowStrictForm(true);
    } else {
      saveMeals();
    }
  };

  if (showStrictForm) {
    return (
      <StrictMenu onClick={() => setShowStrictForm(false)} />
    );
  }

  return (
    <div className={cn(styles.stepWrapper, styles.flexWrapper, styles.physicalActivity)}>
      <ProgressBar />
      <div className={styles.stepHeader}>
        What proteins would you like to exclude?
      </div>
      <div className={styles.selectButtonsWrapper}>
        <MultipleSelectImageButtons
          buttonsData={excludeMealItems}
          onChange={onChangeHandler}
          defaultValue={excludedMeals}
        />
        <button
          className="green-button"
          onClick={onSaveHandler}
          disabled={!excludedMeals}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step7;