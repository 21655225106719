import React, { useState } from 'react';
import cn from 'classnames';

import DatePicker from '../DatePicker/DatePicker';
import ProgressBar from '../ProgressBar/ProgressBar';

import styles from './Steps.module.css';

const Step3 = (props) => {
  const { onSave, stepsData } = props;

  const [birthDate, setBirthDate] = useState(stepsData.birth_date || '');

  const onChangeHandler = (date) => {
    setBirthDate(date);
  };

  const onSaveHandler = () => {
    onSave({ birth_date: birthDate });
  };

  return (
    <div className={cn(styles.stepWrapper, styles.flexWrapper, styles.birthDateWrapper)}>
      <ProgressBar />
      <div className={styles.contentBlock}>
        <div className={styles.stepHeader}>
          When were you born?
        </div>
        <div className={styles.spaceBetween}>
          <div className={styles.inputDateWrapper}>
            <DatePicker
              initialValue={birthDate}
              onChange={onChangeHandler}
              placeholder="Date of birth"
              dateFormat="d MMM yyyy"
            />
            {/* <input 
              type="date" 
              className={styles.mobileDatapicker} 
              value={birthDate}
              onChange={onChangeHandler}
            /> */}
          </div>
          <button
            className="green-button"
            onClick={onSaveHandler}
            disabled={!birthDate}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Step3;