import React from 'react';
import cn from 'classnames';

import styles from './Rating.module.css';

const stars = [1, 2, 3, 4, 5];

const Rating = ({ rating }) => {
  return (
    <div className={styles.rating}>
      {stars.map((number) => (
        <div key={number} className={cn(
          styles.star, 
          { [styles.active]: rating >= number }
        )} />
      ))}
    </div>
  );
};

export default Rating;