import React, { useState } from 'react';
import cn from 'classnames';

import SelectButtons from '../SelectButtons/SelectButtons';
import ProgressBar from '../ProgressBar/ProgressBar';
import styles from './Steps.module.css';

const goalItems = [
  {
    value: 'loose',
    label: 'Lose weight',
  },
  {
    value: 'balanced',
    label: 'Balanced diet',
  },
  {
    value: 'gain',
    label: 'Gain weight',
  },
];

const Step9 = (props) => {
  const { onSave, stepsData } = props;

  const [goal, setGoal] = useState(stepsData.goal || '');

  const onChangeHandler = (value) => {
    setGoal(value);
  };

  const onSaveHandler = () => {
    onSave({ goal });
  };

  return (
    <div className={cn(styles.stepWrapper, styles.wrapperPadding, styles.greenBackground, styles.flexWrapper)}>
      <ProgressBar />
      <div className={styles.stepHeader}>
        What is your goal?
      </div>
      <div className={styles.spaceBetween}>
        <div className={styles.selectButtonsWrapper}>
          <SelectButtons
            buttonsData={goalItems}
            onChange={onChangeHandler}
            defaultValue={goal}
          />
        </div>
        <button
          className={styles.brownButton}
          onClick={onSaveHandler}
          disabled={!goal}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step9;