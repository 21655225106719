import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import styles from './MainFooter.module.css';

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.footerInfo}>
          <Link className={styles.logo} to="/dev" />
          <div className={styles.infoText}>
            Nam posuere accumsan porta. 
            Integer id orci sed ante tincidunt tincidunt sit amet sed libero.
          </div>
          <div className={styles.copyright}>
            © MealWell {currentYear}
          </div>
        </div>
        <div className={styles.footerLinks}>
          <div className={styles.linksTitle}>
            Company
          </div>
          <Link className={styles.footerLink} to="/dev">
            Cras convallis
          </Link>
          <Link className={styles.footerLink} to="/dev">
            Vestibulum faucibus
          </Link>
          <Link className={styles.footerLink} to="/dev">
            Quisque lacinia purus
          </Link>
          <Link className={styles.footerLink} to="/dev">
            Aliquam nec ex
          </Link>
        </div>
        <div className={cn(styles.footerLinks, styles.secondLinksBlock)}>
          <div className={styles.linksTitle}>
            Services
          </div>
          <Link className={styles.footerLink} to="/dev">
            Cras convallis
          </Link>
          <Link className={styles.footerLink} to="/dev">
            Vestibulum faucibus
          </Link>
          <Link className={styles.footerLink} to="/dev">
            Quisque lacinia purus
          </Link>
          <Link className={styles.footerLink} to="/dev">
            Aliquam nec ex
          </Link>
        </div>
        <div className={cn(styles.footerLinks, styles.iosLinks)}>
          <div className={styles.linksTitle}>
            Get App
          </div>
          <a 
            className={styles.appStore} 
            href="https://yahoo.com"
            target="_blank"
            rel="noreferrer"
          />
        </div>
        <div className={styles.socialLinks}>
          <a className={cn(styles.socialLink, styles.facebook)} href="https://yahoo.com" />
          <a className={cn(styles.socialLink, styles.linkedin)} href="https://yahoo.com" />
          <a className={cn(styles.socialLink, styles.twitter)} href="https://yahoo.com" />
          <a className={cn(styles.socialLink, styles.instagram)} href="https://yahoo.com" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;