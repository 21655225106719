import { 
  SET_STEPS, 
  SET_MOBILE_STEPS,
  RESET_STEPS, 
  NEXT_STEP,
 } from '../actions/steps';

const defaultStepsData = {
  step: 0,
  country: 'GB',
};


let initialState = {
  stepsData: { ...defaultStepsData },
};

const steps = (state = initialState, action) => {
  switch (action.type) {
    case SET_STEPS:
      return {
        ...state,
        stepsData: { ...action.payload },
      };

    case SET_MOBILE_STEPS:
      state.stepsData.step++;
      return {
        ...state,
        stepsData: { ...state.stepsData, ...action.payload },
      };

    case RESET_STEPS:
      return {
        ...state,
        stepsData: { ...defaultStepsData },
      };

    case NEXT_STEP:
      state.stepsData.step++;
      console.log('===STEPS DATA', state.stepsData);
      return {
        ...state,
        stepsData: { ...state.stepsData },
      };

    default:
      return state;
  }
};

export default steps;
