export const getFieldFromURLParams = (field) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(field);
};

export const getUserData = () => {
  const userData = localStorage.getItem('user');

  if (userData) {
    return JSON.parse(userData);
  }

  return null;
};

export const setUserData = (userData) => {
  localStorage.setItem('user', JSON.stringify(userData));
};


export const updateUserData = (userData) => {
  const oldUserData = getUserData();
  const newUserData = {
    ...oldUserData,
    ...userData,
  };
  setUserData(newUserData);
};

export const integerValidation = (value) => {
  const number = parseInt(value, 10);
  return number > 0 && number.toString() === value;
};

const poundsGegExp = /^\d+['’]\d+['’]?['”"]?$|^\d+['’]$/;
const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]{2,5}$/;

export const poundsValidation = (value) => {
  value = value.toString();
  if (!value.includes('’') && !value.includes('\'')) {
    return floatValidation(value);
  }

  const isCorrect = poundsGegExp.test(value);

  if (isCorrect) {
    const delimiter = value.includes('’') ? '’' : '\'';
    const [, inches] = value.split(delimiter);
    if (inches >= 12) {
      return false;
    }
  }

  return true;
};

export const floatValidation = (value) => {
  const number = parseFloat(value, 10);
  return number > 0 && number.toString() === value.toString();
};

export const emailValidation = (email) => {
  return emailRegExp.test(email);
};

export const getLocalStorageObject = (field) => {
  const objectJSON = localStorage.getItem(field);
  try {
    return JSON.parse(objectJSON) || {};
  } catch (e) {
    return {};
  }
};

export const setLocalStorageObject = (field, object) => {
  const objectJSON = JSON.stringify(object);
  localStorage.setItem(field, objectJSON);
};

export const updateViewportHeight = () => {
  const updateHeightVariable = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };
  updateHeightVariable();
  window.addEventListener('resize', updateHeightVariable);
};

export const footToFloat = (value) => {
  value = value.toString();
  if ((value.includes('’') || value.includes('\''))) {
    const delimiter = value.includes('’') ? '’' : '\'';

    const [pounds, inches] = value.split(delimiter);

    value = +pounds;

    if (inches) {
      // Don't change it
      value += +(parseFloat(inches) / 12).toFixed(2);
    }
  }
  return value;
};