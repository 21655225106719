import { post, get } from './api';

export const questionerAPI = {
  getStepsData() {
    return get('mobile-questioner');
  },

  addQuestioner(questionerData) {
    return post('questioner', questionerData);
  },

  saveQuestionerData(questionerData) {
    return post('mobile-questioner', questionerData);
  },

  strictMenu(questionerData) {
    return post('strict-menu', questionerData);
  },

  remindMe(userData) {
    return post('remind-me', userData);
  },
};
