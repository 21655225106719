import React from 'react';

import styles from './Steps.module.css';

const StrictMenu = ({ onClick }) => {

  return (
    <div className={styles.stepWrapper}>
      <div className={styles.strictMenuWrapper}>
        <div className={styles.strictMenu}>
          <div className={styles.strictTitle}>
            Unfortunately, your diet is too strict
          </div>
          <div className={styles.strictSubtitle}>
            Your diet is too strict, and we don’t know yet how to create balanced meal plan for you. 
            We will contact you ASAP once we’ll have a solution for you.
          </div>
        </div>
        <button
          className="green-button"
          onClick={onClick}
        >
          Back
        </button>
        <div className={styles.greenLink}>
          Remind me later
        </div>
      </div>
    </div>
  );
};

export default StrictMenu;