import React, { useState, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import cn from 'classnames';

import styles from './DatePicker.module.css';

const CustomInput = forwardRef(({ value, onClick, placeholder }, ref) => (
  <div 
    className={cn(styles.datepicker, !value && styles.placeholder, !value && 'datapicker-placeholder')} 
    onClick={onClick} ref={ref}
  >
    {value || placeholder}
  </div>
));

CustomInput.displayName = 'CustomInput';

const DatePickerComponent = (props) => {
  const {
    onChange,
    initialValue,
    placeholder,
    dateFormat,
  } = props;

  const [date, setDate] = useState(initialValue || '');

  const formatWeekDay = (week) => {
    return week.substring(0, 3);
  };

  const handleChange = (date) => {
    setDate(date);
    onChange(date);
  };

  return (
    <div className={cn(styles.datepickerWrapper, 'datapicker-wrapper')}>
      <DatePicker
        selected={date}
        className={styles.datepicker}
        onChange={handleChange}
        placeholderText={placeholder}
        onKeyDown={(e) => e.preventDefault()}
        formatWeekDay={formatWeekDay}
        maxDate={new Date()}
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={60}
        customInput={<CustomInput placeholder={placeholder} />}
        dateFormat={dateFormat}
      />
    </div>
  );
};

export default DatePickerComponent;
