import React from 'react';
import cn from 'classnames';

import ProgressBar from '../ProgressBar/ProgressBar';
import styles from './Steps.module.css';

const Step10 = (props) => {
  const { onSave } = props;

  return (
    <div className={cn(
      styles.stepWrapper,
      styles.wrapperPadding,
      styles.brownBackground,
      styles.flexWrapper,
      styles.emailForm
    )}>
      <ProgressBar onSave={onSave} />
      <div className={styles.stepHeader}>
        Submit your email to receive your customized meal plan
      </div>
    </div>
  );
};

export default Step10;