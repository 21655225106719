import { post } from './api';

export const authAPI = {
  login(loginData) {
    return post('login', loginData);
  },

  confirmEmail(otp) {
    return post('auth/confirm-email', { otp });
  },

  register(registerData) {
    return post('registration', registerData);
  },
};
