import { authAPI } from '../api/auth';
import { setUserData } from '../utils/functions';
import { actionWrapper } from './actionWrapper';

export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_PART_USER_DATA = 'SET_PART_USER_DATA';

export const confirmEmail = (otp, callback, errorCallback) => actionWrapper(async () => {
  const confirmResult = await authAPI.confirmEmail(otp);
  callback?.();
  console.log('===confirmResult', confirmResult);
}, errorCallback);

const loginProceed = (userData) => {
  setUserData(userData);

  return {
    type: SET_USER_DATA,
    payload: userData,
  };
};

const logoutProceed = () => {
  return {
    type: SET_USER_DATA,
    payload: null,
  };
};

export const login = (loginData, { setFieldError }, callback) => actionWrapper(async (dispatch) => {
  const authData = await authAPI.login(loginData);
  dispatch(loginProceed(authData));
  callback?.();
}, (errors) => {
  Object.keys(errors).forEach(field => {
    setFieldError(field, errors[field]);
  });
});


export const logout = () => () => {
  localStorage.removeItem('user');
  window.location = '/';
  return logoutProceed();
};

export const register = (registerData, { setFieldError }, callback) => actionWrapper(async () => {
  await authAPI.register(registerData);
  callback?.();
}, (errors) => {
  Object.keys(errors).forEach(field => {
    setFieldError(field, errors[field]);
  });
});