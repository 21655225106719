import React from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import cn from 'classnames';

import { login } from '../../actions/auth';
import { GoogleButton } from '../../components/GoogleButton/GoogleButton';
import { Input } from '../../components/FormsControls/FormControls';
import styles from './Login.module.css';

const LoginSchema = Yup.object().shape({
  password: Yup.string().min(6, 'Password too short').required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
});

const Login = (props) => {
  const { login } = props;
  const navigate = useNavigate();

  const loginHandler = (data, formikData) => {
    login(data, formikData, () => navigate('/'));
  };

  return (
    <div className={styles.wrapper}>
      <Link 
        className={'back-button'} 
        to="/get-meal-plan" 
      />
      <div className={styles.content}>
        <div className="medium-text">
          Let’s create a meal plan for you
        </div>
        <div className="small-text">
          Meal Well makes eating well easy and enjoyable every day.
        </div>
        <Formik
          onSubmit={loginHandler}
          initialValues={{ email: '', password: '' }}
          validationSchema={LoginSchema}
        >
          <Form className={styles.form}>
            <Input
              className={styles.input}
              name="email"
              type="text"
              placeholder="Email"
            />
            <Input
              className={styles.input}
              name="password"
              type="password"
              placeholder="Password"
            />
            <div className={styles.forgotWrapper}>
              <Link className={styles.forgot}>
                Forgot password?
              </Link>
            </div>
            <button className={cn('green-button', styles.login)}>
              Log in
            </button>
            <GoogleButton />
            <Link className={cn('transparent-button', styles.login)} to="/register">
              Create account
            </Link>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

const mapDispatchToProps = { login };

export default connect(null, mapDispatchToProps)(Login);
