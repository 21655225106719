import protein1 from '../assets/images/protein-1.png';
import protein2 from '../assets/images/protein-2.png';
import protein3 from '../assets/images/protein-3.png';
import protein4 from '../assets/images/protein-4.png';
import noneAbove from '../assets/images/none-above.png';

export const excludeMealItems = [
  {
    value: 'dairy',
    label: 'Dairy',
    icon: protein1,
  },
  {
    value: 'fish',
    label: 'Seafood and fish',
    icon: protein2,
  },
  {
    value: 'meat',
    label: 'Meat',
    icon: protein3,
  },
  {
    value: 'eggs',
    label: 'Eggs',
    icon: protein4,
  },
  {
    value: '',
    label: 'None of above',
    icon: noneAbove,
  },
];
