import React, { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';

import { floatValidation, poundsValidation, footToFloat } from '../../utils/functions';
import ProgressBar from '../ProgressBar/ProgressBar';
import RadioButton from '../RadioButton/RadioButton';

import styles from './Steps.module.css';

const CENTIMETER = 'cm';
const FOOT = 'foot';
const INCH = 'inch';

const heightPlaceholder = {
  [CENTIMETER]: '0 cm',
  [FOOT]: '0’0”',
  [INCH]: '0 inches',
};

const Step5 = (props) => {
  const { onSave, stepsData } = props;

  const [heightUnit, setHeightUnit] = useState(stepsData.height_unit || CENTIMETER);
  const [height, setHeight] = useState(stepsData.height || '');
  const [error, setError] = useState(null);
  const [afterChecking, setAfterChecking] = useState(false);


  const validate = useCallback(() => {
    let error = null;

    if (heightUnit === FOOT) {
      if (!poundsValidation(height)) {
        error = 'Height must be in the correct format';
      }
    } else if (!floatValidation(height)) {
      error = 'Height must be number';
    }

    setError(error);
    return !error;
  }, [height, heightUnit]);

  useEffect(() => {
    if (afterChecking) {
      validate();
    }
  }, [afterChecking]);

  useEffect(() => {
    if (height) {
      validate();
    }
  }, [validate, heightUnit]);

  const onChangeUnit = (event) => {
    setHeightUnit(event.target.value);
  };

  const onChangeHeight = (event) => {
    setHeight(event.target.value);
  };

  const onSaveHandler = () => {
    if (!validate()) {
      return setAfterChecking(true);
    }

    const stepData = { 
      height_unit: heightUnit,
      height: heightUnit === FOOT ? footToFloat(height) : height
    };

    onSave(stepData);
  };

  return (
    <div className={cn(styles.stepWrapper, styles.flexWrapper, styles.wideStep)}>
      <ProgressBar />
      <div className={styles.stepHeader}>
        How tall are you?
      </div>
      <div className={styles.spaceBetween}>
        <div>
          <div className={styles.inputWrapper}>
            <input
              className={styles.input}
              onChange={onChangeHeight}
              value={height}
              placeholder={heightPlaceholder[heightUnit]}
            />
            {error && <div className={styles.error}>{error}</div>}
          </div>
          <div className={cn(styles.radioButtons, styles.wideRadioButtons)}>
            <RadioButton
              name="heightUnit"
              value={CENTIMETER}
              onChange={onChangeUnit}
              checked={heightUnit === CENTIMETER}
              label="Cm"
            />
            <RadioButton
              name="heightUnit"
              value={FOOT}
              onChange={onChangeUnit}
              checked={heightUnit === FOOT}
              label="Foot"
            />
            <RadioButton
              name="heightUnit"
              value={INCH}
              onChange={onChangeUnit}
              checked={heightUnit === INCH}
              label="Inches"
            />
          </div>
          <button
            className="green-button"
            onClick={onSaveHandler}
            disabled={!height}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Step5;