import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import Marquee from 'react-fast-marquee';
import { 
  Swiper, 
  SwiperSlide,
 } from 'swiper/react';
 import { 
   Pagination,
   Autoplay,
  } from 'swiper/modules';

import Rating from '../../components/Rating/Rating';
import MainHeader from '../../components/MainHeader/MainHeader';
import MainFooter from '../../components/MainFooter/MainFooter';
import styles from './Dev.module.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import sayAvatar1 from '../../assets/images/say_avatar_1.png';
import sayAvatar2 from '../../assets/images/say_avatar_2.png';

const loveAppSliderData = [
  'Meal planning',
  'Quick delivery',
  'No food waste',
  'Weekly plans',
];

const faqItem = {
  question: 'How can I upload the App from AppStore?',
  answer: `
  Arcu at dictum sapien, mollis. 
    Vulputate sit id accumsan, ultricies. 
    In ultrices malesuada elit mauris.
  `
};

const faqItems = [
  faqItem,
  faqItem,
  faqItem,
  faqItem,
  faqItem,
  faqItem,
];

const LoveSliderContent = () => (
  <div className={styles.loveSliderContent}>
    <div className={styles.loveSliderInfo}>
      <div className={styles.loveTitle}>
        Weekly Meal Plans
      </div>
      <div className={styles.loveSubTitle}>
        Get personalized weekly meal plans and have groceries delivered in one click
      </div>
      <a 
        href="https://google.com" 
        target="_blank" 
        rel="noreferrer"
        className={styles.greenButton}
      >
        Try free
      </a>
      <div className={styles.usersBlock}>
        <div className={styles.avatarsBlock}>
          <div className={styles.avatar} />
          <div className={styles.avatar} />
          <div className={styles.avatar} />
        </div>
        <div className={styles.rating}>
          <div className={styles.happyCustomers}>
            <div className={styles.customersAmount}>
              439
            </div>
            <div className={styles.customersText}>
              Happy Customers
            </div>
          </div>
          <div className={styles.happyCustomers}>
            <div className={styles.customersAmount}>
              4.8/5
            </div>
            <div className={styles.customersText}>
              <Rating rating={4} />
              Rating
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const UserSaySliderContent = () => (
  <div className={styles.userSayContent}>
    <div className={styles.userSayInfo}>
      <div className={styles.loveTitle}>
        Amanda, 26 y.o.
      </div>
      <div className={styles.loveSubTitle}>
        Life-Changing Meal Planning App! ⭐⭐⭐⭐⭐
      </div>
      <div className={styles.loveSubTitle}>
        “I can&apos;t say enough good things about this app! As someone who used to struggle with meal prep and grocery shopping, this app has completely transformed my routine. The personalized meal plans, easy-to-follow recipes, and automated grocery lists save me so much time and stress. I love that I can filter meals based on my dietary needs, and the app even helps reduce food waste by optimizing ingredients.
        If you&apos;re looking to eat healthier, save money, and simplify your life, this app is a must-have! Highly recommend! 🙌🍽️”
      </div>
    </div>
    <div className={cn(styles.userComment, styles.firstUserComment)}>
      <img className={styles.sayAvatar} src={sayAvatar1} alt="avatar" />
      <div>
        <div className={styles.userCommentTitle}>
          Ronald Richards
        </div>
        <div className={styles.userCommentSubTitle}>
          One of the best meal planning app I have ever used.
        </div>
      </div>
    </div>
    <div className={cn(styles.userComment, styles.secondUserComment)}>
      <img className={styles.sayAvatar} src={sayAvatar2} alt="avatar" />
      <div>
        <div className={styles.userCommentTitle}>
          Liza Richards
        </div>
        <div className={styles.userCommentSubTitle}>
          One of the best meal planning app I have ever used.
        </div>
      </div>
    </div>
  </div>
);

const howItWorkContent = [
  () => (
    <div className={styles.howItWorksItem}>
      Get personalized weekly meal plans and have groceries delivered in one click
    </div>
  ),
  () => (
    <div className={styles.howItWorksItem1} />
  ),
  () => (
    <div className={styles.howItWorksItem}>
      Get personalized weekly meal plans and have groceries delivered in one click
    </div>
  ),
  () => (
    <div className={styles.howItWorksItem1} />
  ),
];

const Dev = () => {
  const [swiper, setSwiper] = useState(null);
  const [loveSliderIndex, setLoveSliderIndex] = useState(0);

  useEffect(() => {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.documentElement.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
  }, []);

  const changeSliderPage = (index) => {
    if (swiper) {
      swiper.slideToLoop(index);
    }
  };

  return (
    <div className={styles.page}>
      <div className={styles.wrapper}>
        <MainHeader />
        <section className={styles.bannerSection}>
          <div className={styles.play} />
          <div className={styles.bannerInfoBlock}>
            <div className={styles.bannerTitle}>
              Stress-Free Meal Planning, Simplified.
            </div>
            <div className={styles.bannerSubTitle}>
              Get personalized weekly meal plans and have groceries delivered in one click
            </div>
            <a 
              href="https://google.com" 
              target="_blank" 
              rel="noreferrer"
              className={styles.greenButton}
            >
              Try free
            </a>
          </div>
        </section>
        <section className={cn(styles.section, styles.loveAppSection)}>
          <div className={styles.sectionTitle}>
            Why You’ll Love Our App
          </div>
          <div className={styles.sliderButtons}>
            {loveAppSliderData.map((label, index) => (
              <button 
                key={index}
                onClick={() => changeSliderPage(index)} 
                className={cn(
                  styles.sliderButton, 
                  { [styles.activeSliderButton]: index === loveSliderIndex }
                )}
              >
                {label}
              </button>
            ))}
          </div>
          <div className={styles.loveSliderWrapper}>
            <Swiper 
              modules={[Pagination, Autoplay]}
              onSwiper={setSwiper}
              className={styles.slider} 
              spaceBetween="24" 
              slidesPerView="1" 
              navigation
              pagination={{ 
                clickable: true,
              }}
              autoplay={{ 
                delay: 3000,
                disableOnInteraction: false,
              }}  
              // centeredSlides
              // loop   
              onSlideChange={swiper => setLoveSliderIndex(swiper.realIndex)}
              onSlideEnd={() => setLoveSliderIndex(0)}
            >
              <SwiperSlide className={styles.loveSliderSlide}>
                <LoveSliderContent />
              </SwiperSlide>
              <SwiperSlide className={styles.loveSliderSlide}>
                <LoveSliderContent />
              </SwiperSlide>
              <SwiperSlide className={styles.loveSliderSlide}>
                <LoveSliderContent />
              </SwiperSlide>
              <SwiperSlide className={styles.loveSliderSlide}>
                <LoveSliderContent />
              </SwiperSlide>
            </Swiper>
          </div>
        </section>
        <section className={cn(styles.section, styles.howItWorksSection)}>
          <div className={styles.sectionTitle}>
            How it works
          </div>
          <div className={styles.sectionSubTitle}>
            Get personalized weekly meal plans and have groceries delivered in one click
          </div>
          <a 
            href="https://google.com" 
            target="_blank" 
            rel="noreferrer"
            className={styles.greenButton}
          >
            Try free
          </a>
          <div className={styles.howItWorksWrapper}>
            <Swiper 
              modules={[Pagination, Autoplay]}
              className={styles.slider} 
              spaceBetween="32" 
              slidesPerView="auto"
              pagination={{ 
                clickable: true,
              }}
              autoplay={{ 
                delay: 3000,
                disableOnInteraction: false,
              }}     
              centeredSlides
              loop
            >
              {howItWorkContent.map((SliderContent, index) => (
                <SwiperSlide key={index}>
                  <SliderContent />
                </SwiperSlide>
              ))}
              {howItWorkContent.map((SliderContent, index) => (
                <SwiperSlide key={index}>
                  <SliderContent />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className={styles.howItWorksWrapperMobile}>
            <Swiper 
              modules={[Pagination, Autoplay]}
              className={styles.slider} 
              spaceBetween="32" 
              slidesPerView="auto" 
              navigation
              pagination={{ 
                clickable: true,
              }}
              autoplay={{ 
                delay: 3000,
                disableOnInteraction: false,
              }}   
              centeredSlides 
              loop
            >
              {howItWorkContent.map((SliderContent, index) => (
                <SwiperSlide key={index}>
                  <SliderContent />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
        <section className={cn(styles.bannerSection, styles.nextDaySection)}>
          <div className={styles.marque}>
            <Marquee autoFill>
              <div className={styles.marqueItem}>Weekly plan</div>
              <div className={styles.marqueItem}>Everyday</div>
              <div className={styles.marqueItem}>Groceries</div>
              <div className={styles.marqueItem}>Stress-Free</div>
            </Marquee>
          </div>
          <div className={styles.bannerInfoBlock}>
            <div className={styles.bannerInfoWrapper}>
              <div className={styles.bannerTitle}>
                Next day groceries delivery
              </div>
              <div className={styles.bannerSubTitle}>
                Get personalized weekly meal plans and have groceries delivered in one click
              </div>
              <a 
                href="https://google.com" 
                target="_blank" 
                rel="noreferrer"
                className={styles.greenButton}
              >
                Try free
              </a>
            </div>
          </div>
          <div className={cn(styles.marque, styles.shopMarque)}>
            <Marquee autoFill>
              <div className={cn(styles.shopMarqueItem, styles.amazon)} />
              <div className={cn(styles.shopMarqueItem, styles.morrison)} />
              <div className={cn(styles.shopMarqueItem, styles.tesko)} />
            </Marquee>
          </div>
        </section>
        <section className={cn(styles.section, styles.userSaySection)}>
          <div className={styles.sectionTitle}>
            What Our Users Say
          </div>
          <div className={styles.userSayWrapper}>
            <Swiper 
              modules={[Pagination, Autoplay]}
              className={styles.slider} 
              spaceBetween="24" 
              slidesPerView="1" 
              navigation
              pagination={{ 
                clickable: true,
              }}
              autoplay={{ 
                delay: 3000,
                disableOnInteraction: false,
              }} 
              loop   
            >
              <SwiperSlide className={styles.loveSliderSlide}>
                <UserSaySliderContent />
              </SwiperSlide>
              <SwiperSlide className={styles.loveSliderSlide}>
                <UserSaySliderContent />
              </SwiperSlide>
              <SwiperSlide className={styles.loveSliderSlide}>
                <UserSaySliderContent />
              </SwiperSlide>
              <SwiperSlide className={styles.loveSliderSlide}>
                <UserSaySliderContent />
              </SwiperSlide>
            </Swiper>
          </div>
        </section>
        <section className={cn(styles.section, styles.whatSaySection)}>
          <div className={styles.tryFreeToday}>
            <div className={styles.tryContent}>
              <div className={styles.title}>Try free today</div>
              <div className={styles.subTitle}>
                Get personalized weekly meal plans and have groceries delivered in one click
              </div>
              <a 
                href="https://google.com" 
                target="_blank" 
                rel="noreferrer"
                className={styles.downloadButton}
              />
            </div>


            <div className={styles.usersBlock}>
              <div className={styles.avatarsBlock}>
                <div className={styles.avatars}>
                  <div className={styles.avatar} />
                  <div className={styles.avatar} />
                  <div className={styles.avatar} />
                </div>
              </div>
              <div className={styles.rating}>
                <div className={styles.happyCustomers}>
                  <div className={styles.customersAmount}>
                    439
                  </div>
                  <div className={styles.customersText}>
                    Happy Customers
                  </div>
                </div>
                <div className={styles.happyCustomers}>
                  <div className={styles.customersAmount}>
                    4.8/5
                  </div>
                  <div className={styles.customersText}>
                    <Rating rating={4} />
                    Rating
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={cn(styles.section, styles.faqSection)}>
          <div className={styles.sectionTitle}>
            FAQ
          </div>
          <div className={styles.faqItems}>
            {faqItems.map((faqData, index) => (
              <div key={index} className={styles.faqItem}>
                <div className={styles.faqTitle}>
                  {faqData.question}
                </div>
                <div className={styles.faqText}>
                  {faqData.answer}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.faqMobileItems}>
            <Swiper 
              modules={[Pagination, Autoplay]}
              className={styles.slider} 
              spaceBetween="24" 
              slidesPerView="1" 
              navigation
              pagination={{ 
                clickable: true,
              }}
              autoplay={{ 
                delay: 3000,
                disableOnInteraction: false,
              }} 
              loop   
            >
              {faqItems.map((faqData, index) => (
                <SwiperSlide key={index} className={styles.loveSliderSlide}>
                  <div key={index} className={styles.faqItem}>
                    <div className={styles.faqTitle}>
                      {faqData.question}
                    </div>
                    <div className={styles.faqText}>
                      {faqData.answer}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
        <MainFooter />
      </div>
    </div>
  );
};

export default Dev;
