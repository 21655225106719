import React from 'react';
import cn from 'classnames';

import SelectImageButtons from '../SelectImageButtons/SelectImageButtons';
import ProgressBar from '../ProgressBar/ProgressBar';

import casualLife1 from '../../assets/images/casual-life-1.png';
import casualLife2 from '../../assets/images/casual-life-2.png';
import casualLife3 from '../../assets/images/casual-life-3.png';
import casualLife4 from '../../assets/images/casual-life-4.png';
import styles from './Steps.module.css';

const howActiveItems = [
  {
    image: casualLife1,
    value: 'passive',
    label: 'Sedentary',
  },
  {
    image: casualLife2,
    value: 'moderate',
    label: 'Exercise 1-3 times per week',
  },
  {
    image: casualLife3,
    value: 'highly',
    label: 'Exercise 4-5 times per week',
  },
  {
    image: casualLife4,
    value: 'sportsmen',
    label: 'Professional sportsman',
  },
];

const Step6 = (props) => {
  const { onSave } = props;

  const onSaveHandler = (physicalActivity) => {
    onSave({ physical_activity: physicalActivity });
  };

  return (
    <div className={cn(styles.stepWrapper, styles.flexWrapper, styles.physicalActivity)}>
      <ProgressBar />
      <div className={cn(styles.stepHeader, styles.nowrap)}>
        How active are you?
      </div>
      <div className={styles.selectButtonsWrapper}>
        <SelectImageButtons
          buttonsData={howActiveItems}
          onChange={onSaveHandler}
        />
      </div>
    </div>
  );
};

export default Step6;